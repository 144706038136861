export type MessageEventData =
  | AuthorizeCancelEvent
  | AuthorizeErrorEvent
  | AuthorizeSuccessEvent
  | ResizeIframeEvent
  | ShowAuthorizePageEvent
  | ShopUserMatchedEvent
  | ShopUserNotMatchedEvent
  | CustomFlowSideEffectEvent
  | CompletedEvent
  | AuthorizeLoadedEvent
  | AuthorizeContentEvent
  | ProcessingEvent
  | CloseRequestedEvent
  | EmailChangeRequestedEvent
  | PopUpOpenedEvent
  | VerificationStepChangedEvent;

/**
 * Allows the iframe size to match the UI that is rendered by Pay. Receiving this event is also an indicator of a
 * successful first render of the Authorize page.
 */
export interface ResizeIframeEvent {
  type: 'resize_iframe';
  height: number;
  width: number;
}

/**
 * Sent by Pay when a user has reached the end of the Login with Shop flow, whether they have logged in through Shop, or not.
 */
export interface CompletedEvent {
  type: 'completed';
  loggedIn: boolean;
  shouldFinalizeLogin: boolean;
  email?: string;
  shopAccountUuid?: string;
  customerAccessToken?: string;
  customerAccessTokenExpiresAt?: string;
  shopPayInstallmentsOnboarded?: boolean;
  avatar?: string;
  givenNameFirstInitial?: string;
}

/**
 * Sent by Pay if an unauthenticated user clicks the Continue button. This asks the SDK to show the Authorize page in a
 * popup.
 */
interface ShowAuthorizePageEvent {
  type: 'show_authorize_page';
}

/**
 * Sent by Pay after a successful authorization. This can be sent by both the iframe (if the user was already logged in
 * and a personalized button was shown) as well as the popup (once the user completes the sign-in flow).
 */
interface AuthorizeSuccessEvent {
  type: 'authorize_success';
  payload: any;
}

/**
 * Sent by Pay if a user wishes to cancel the Authorization flow. This asks the SDK to close the popup with the
 * Authorize page.
 */
interface AuthorizeCancelEvent {
  type: 'authorize_cancel';
}

/**
 * Emitted when the web component encountered an unrecoverable error: either because the component was misconfigured,
 * because of a server failure, or because the user was blocked.
 */
export interface AuthorizeErrorEvent {
  type: 'error';
  email?: string;
  code: SdkErrorCode;
  message: string;
}

/**
 * Sent by pay when the component is mounted and detects user loggedin status
 */
export interface AuthorizeLoadedEvent {
  type: 'loaded';
  userFound: boolean;
  clientName: string;
  userNameKnown?: boolean;
  logoSrc?: string;
  privacyPolicyUrl?: string;
  termsOfServiceUrl?: string;
  authenticationLevelRequired?: AuthenticationLevel;
  personalizeConsentChallenge?: boolean;
}

interface AuthorizeContentEvent {
  type: 'content';
  title: string;
  description: string;
  disclaimer: string;
  authorizeState: AuthorizeState;
}

export type ProcessingStatus = 'loading' | 'success' | 'error';

interface ProcessingEvent {
  type: 'processing_status_updated';
  status: ProcessingStatus;
  email: string;
}

export interface ShopUserMatchedEvent {
  type: 'shop_user_matched';
  userCookieExists: boolean;
  description?: string;
  maskedPhoneNumber?: string;
  hasName?: boolean;
  personalizeConsentChallenge?: boolean;
}

export interface ShopUserNotMatchedEvent {
  type: 'shop_user_not_matched';
  apiError?: 'limit_exceeded';
}

export interface CloseRequestedEvent {
  type: 'close_requested';
}

interface EmailChangeRequestedEvent {
  type: 'email_change_requested';
}

export interface VerificationStepChangedEvent {
  type: 'verification_step_changed';
  step: 'sms' | 'email' | 'one_click' | 'webauthn';
  phone?: string;
  email?: string;
}

export interface CustomFlowSideEffectEvent {
  type: 'custom_flow_side_effect';
  // currently only used for Prequal flow, but can be extended to other flows
  flow: ShopActionType.Prequal;
  shopPayInstallmentsOnboarded?: boolean;
  fontAssetLoaded?: boolean;
}

/**
 * Defines event types emitted by the login button web component.
 */
export type ShopLoginEventType =
  | 'error'
  | 'load'
  | 'loginsuccess'
  | 'shopusermatched'
  | 'shopusernotmatched';

export interface PopUpOpenedEvent {
  type: 'pop_up_opened';
  popUpName?: string;
  popUpFeatures?: string;
}

/**
 * Shop Login Flow Version - '1' refers to M1, '2' refers to M2.
 */
export type Version = '1' | '2';

/**
 * Authentication, - 'email' or 'phone'.
 * If 'email' is passed, Pay will only allow email verified users to authorize.
 * If 'phone' is passed, Pay will only allow phone verified users to authorize.
 */
export enum AuthenticationLevel {
  Email = 'email',
  Phone = 'phone',
  PhoneOwnershipVerified = 'phone_ownership_verified',
}

/**
 * Client based variables that can be used in supported translation templates.
 */
export interface ClientTextTemplateVariables {
  [key: string]: any;
  clientName: string;
  store: string;
  privacyPolicyUrl?: string;
  termsOfServiceUrl?: string;
  email?: string;
  phone?: string;
}

/**
 * Shop Login Action Type
 * - Follow: Follow in the shop app
 * - Default: Log in with no side-effects (i.e. for Legacy Customer Accounts)
 */
export enum ShopActionType {
  Follow = 'follow',
  Default = 'default',
  Custom = 'custom',
  Prequal = 'prequal',
  PopUp = 'pop_up',
  CheckoutSheet = 'checkout_sheet',
}

export enum AuthorizeState {
  Start = 'start',
  SignUp = 'signup',
  Verify = 'verify',
  Captcha = 'captcha',
  OneClick = 'one-click',
}

/**
 * Collect the OAuth parameters to initiate the OAuth flow.
 * @member {string} redirectType The redirect type of the OAuth request, for example 'top_frame' or 'pop_up'.
 * @member {string} responseType The response type of the OAuth request, for example 'code' or 'id_token'.
 * @member {string} responseMode The response mode of the OAuth request, for example 'query' or 'fragment'.
 * @member {string} redirectUri The redirect URI of the OAuth request, has to be registered as a redirect uri in shop-accounts for the client
 * @member {string} codeChallenge The code challenge of the OAuth request, one of the PKCE parameters.
 * @member {string} codeChallengeMethod The code challenge method of the OAuth request, one of the PKCE parameters (for example, 'S256').
 * @member {string} state The state of the OAuth request, one of the OAuth parameters, intended to be mirrored back to client.
 * @member {string} scope The scope of the OAuth request, one of the OAuth parameters, intended to be used to request specific scopes, for example 'openid email'.
 */
export interface OAuthParams {
  responseType?: string;
  responseMode?: string;
  redirectType?: 'top_frame' | 'pop_up' | 'iframe';
  redirectUri?: string;
  codeChallenge?: string;
  codeChallengeMethod?: string;
  state?: string;
  scope?: string;
}

/**
 * The V1 flow (direct to Shop Server) has more strict requirements for the OAuth parameters.
 * @member {string} clientId The required client ID of the client initiating the OAuth request, has to be registered in shop-accounts.
 */
export interface OAuthParamsV1 extends OAuthParams {
  clientId: string;
}

export interface PopupWindowParams {
  popUpName?: string;
  popUpFeatures?: string;
}

/**
 * SDK Error Codes, emitted from Pay.
 * Should be in sync with: https://github.com/Shopify/pay/blob/master/app/ui/types/account.ts#L156-L164
 */
export enum SdkErrorCode {
  ApiUnavailable = 'api_unavailable',
  InvalidApiKey = 'invalid_api_key',
  ServerError = 'server_error',
  UserBlocked = 'user_blocked',
  NoDiscountReceived = 'no_discount_received',
  InvalidAnalyticsContext = 'invalid_analytics_context',
  InstallmentsIneligible = 'installments_ineligible',
  CaptchaChallenge = 'captcha_challenge',
  RetriableServerError = 'retriable_server_error',
}

export enum AuthorizeModalTextActionType {
  Init = 'init',
  Restart = 'restart',
  UserMatched = 'user_matched',
  UserNotMatched = 'user_not_matched',
  VerificationStepChanged = 'verification_step_changed',
  CustomizationValidityChanged = 'customization_validity_changed',
  PopUpOpened = 'pop_up_opened',
}

interface AuthorizeModalTextInitAction {
  type: AuthorizeModalTextActionType.Init;
  payload: Pick<
    AuthorizeLoadedEvent,
    | 'clientName'
    | 'userFound'
    | 'userNameKnown'
    | 'privacyPolicyUrl'
    | 'termsOfServiceUrl'
    | 'personalizeConsentChallenge'
  >;
}

interface AuthorizeModalTextRestartAction {
  type: AuthorizeModalTextActionType.Restart;
}

interface AuthorizeModalTextUserMatchedAction {
  type: AuthorizeModalTextActionType.UserMatched;
  payload: Required<
    Pick<
      ShopUserMatchedEvent,
      'userCookieExists' | 'hasName' | 'personalizeConsentChallenge'
    >
  >;
}

interface AuthorizeModalTextUserNotMatchedAction {
  type: AuthorizeModalTextActionType.UserNotMatched;
}

interface AuthorizeModalTextVerificationStepChangedAction {
  type: AuthorizeModalTextActionType.VerificationStepChanged;
  payload: Pick<VerificationStepChangedEvent, 'step' | 'phone' | 'email'>;
}

interface AuthorizeModalTextPopUpOpened {
  type: AuthorizeModalTextActionType.PopUpOpened;
  payload: Pick<PopUpOpenedEvent, 'popUpName' | 'popUpFeatures'>;
}

export enum AuthorizeModalTextStep {
  Start = 'start',
  SignUp = 'signup',
  EmailVerification = 'email_verification',
  PhoneVerification = 'phone_verification',
  WebAuthnVerification = 'webauthn_verification',
  PopUpOpened = 'pop_up_opened',
  OneClick = 'one_click',
  PersonalizeConsent = 'personalize_consent',
}

export type AuthorizeModalTextAction =
  | AuthorizeModalTextInitAction
  | AuthorizeModalTextPopUpOpened
  | AuthorizeModalTextRestartAction
  | AuthorizeModalTextUserMatchedAction
  | AuthorizeModalTextUserNotMatchedAction
  | AuthorizeModalTextVerificationStepChangedAction;

export interface AuthorizeModalTextReducer {
  (
    action: AuthorizeModalTextAction,
    state: AuthorizeModalTextViewState,
  ): AuthorizeModalTextViewState;
}

export interface AuthorizeModalTextViewState {
  step: AuthorizeModalTextStep;
  configurable: boolean;
  headerVisible: boolean;
  headerDividerVisible: boolean;
  headerTemplate: string;
  descriptionTemplate: string;
  userNameKnown: boolean;
  templateVariables: ClientTextTemplateVariables;
  sessionDetected: boolean;
}

/**
 * Shop Login UX Mode - 'redirect' refers to the full page redirect.
 */
export type UxMode = 'redirect';
